import { all, call, put, StrictEffect, take } from 'redux-saga/effects';
import {
  getUserInfo,
  getUserInfoByToken,
  getUserInfoSuccess,
  grantLogin,
  grantLoginSuccess,
  logout,
  logoutSuccess,
  setKpiDisplaySettings,
  setKpiDisplaySettingsSuccess,
} from './authoriztionSlice';
import Api from '../../services/api';
import { PayloadModel } from '../../models/global';
import { KpiDisplayOptionsModel, UserPayloadModel } from '../../components/common/toolbar/settingsPanel/settingsPanel.types';

function* getUserInfoSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { id },
    }: { payload: UserPayloadModel } = yield take(getUserInfo.toString());

    try {
      const response = yield call(Api.get, `user-short/${id}`);
      localStorage.removeItem('prevBuildingId');
      if (response) {
        yield put(getUserInfoSuccess(response.data.resource));
      }
    } catch (e: any) {
      console.log('KpiDataError', e.response);
      // if (e.response.status === 400) {
      //   callback(e.response.data.errors);
      // }
    }
  }
}
function* grantLoginSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { grant_token, callback_url },
    }: { payload: { grant_token: string; callback_url: string } } = yield take(grantLogin.toString());

    try {
      const response = yield call(Api.post, `grant-login`, { callback_url, grant_token });
      if (response) {
        yield put(grantLoginSuccess(response.data.resource));
      }
    } catch (e: any) {
      console.log('KpiDataError', e.response);
    }
  }
}

function* logoutSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    yield take(logout.toString());

    try {
      const response = yield call(Api.get, `logout`);
      if (response) {
        yield put(logoutSuccess(response.data.resource));
      }
    } catch (e: any) {
      console.log('KpiDataError', e.response);
      // if (e.response.status === 400) {
      //   callback(e.response.data.errors);
      // }
    }
  }
}

function* getUserInfoByTokenSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    yield take(getUserInfoByToken.toString());

    try {
      const response = yield call(Api.get, `user-short`);
      localStorage.removeItem('prevBuildingId');
      if (response) {
        yield put(getUserInfoSuccess(response.data.resource));
      }
    } catch (e: any) {
      console.log('KpiDataError', e.response);
      // if (e.response.status === 400) {
      //   callback(e.response.data.errors);
      // }
    }
  }
}

function* setKpiDisplaySettingsSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { data, callback },
    }: PayloadModel<KpiDisplayOptionsModel, null> = yield take(setKpiDisplaySettings.toString());

    try {
      const response = yield call(Api.post, `kpi-set-user-options`, { data: data });
      if (response) {
        yield put(setKpiDisplaySettingsSuccess(response.data.resource));
      }
    } catch (e: any) {
      console.log('KpiDataError', e.response);
      // if (e.response.status === 400) {
      //   callback(e.response.data.errors);
      // }
    }
  }
}

export function* saga() {
  yield all([getUserInfoSaga(), setKpiDisplaySettingsSaga(), grantLoginSaga(), logoutSaga(), getUserInfoByTokenSaga()]);
}
