import React, { MouseEvent } from 'react';
import SVG from 'react-inlinesvg';
import cn from 'classnames';
import './TokenIconStyles.scss';
import ChevronDownIc from '../../../assets/tokenIcons/chevron-down/chevron-down-12.svg';
import ChevronRightIc from '../../../assets/tokenIcons/chevron-right/chevron-right-16.svg';
import ChevronLightIc from '../../../assets/tokenIcons/chevron-left/chevron-left-12.svg';
import CloseIc from '../../../assets/tokenIcons/close/close-12.svg';
import CheckIc from '../../../assets/tokenIcons/check/check-16.svg';
import DashIc from '../../../assets/tokenIcons/dash/dash-16.svg';
import SearchIc from '../../../assets/tokenIcons/search/search-12.svg';
import ConfigurationIc from '../../../assets/tokenIcons/configuration/configuration-12.svg';
import Logomark from '../../../assets/tokenIcons/logomark/logomark-16.svg';
import Logout from '../../../assets/tokenIcons/logout/logout-20.svg';

export type TokenIconName =
  | 'chevron-down'
  | 'close'
  | 'chevron-right'
  | 'check'
  | 'chevron-left'
  | 'dash'
  | 'search'
  | 'configuration'
  | 'logomark'
  | 'logout';
export type TokenIconSize = 104 | 88 | 40 | 32 | 24 | 20 | 16 | 12 | 4;

export interface TokenIconProps {
  iconName: TokenIconName;
  size: TokenIconSize;
  isClickable?: boolean;
  clickHandler?: (e: MouseEvent) => void;
  customClass?: string;
}

const TokenIcon: React.FC<TokenIconProps> = ({ iconName, size, isClickable, customClass, clickHandler }) => {
  const themeClass = 'b-tokenIcon-light';

  const handleClick = (e: MouseEvent) => {
    if (clickHandler) {
      e.stopPropagation();
      clickHandler(e);
    }
  };

  const getImg = () => {
    switch (iconName) {
      case 'chevron-down': {
        return ChevronDownIc;
      }
      case 'close': {
        return CloseIc;
      }
      case 'configuration': {
        return ConfigurationIc;
      }
      case 'chevron-right': {
        return ChevronRightIc;
      }
      case 'check': {
        return CheckIc;
      }
      case 'chevron-left': {
        return ChevronLightIc;
      }
      case 'dash': {
        return DashIc;
      }
      case 'search': {
        return SearchIc;
      }
      case 'logomark': {
        return Logomark;
      }
      case 'logout': {
        return Logout;
      }
    }
  };

  return (
    <>
      <SVG
        onClick={handleClick}
        className={cn(themeClass, {
          ['-clickable']: isClickable || clickHandler,
          [`${customClass}`]: customClass,
        })}
        src={getImg()}
      />
    </>
  );
};

export default TokenIcon;
