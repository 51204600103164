import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AdvancedDisplaySettingsModel,
  DisplaySettingsModel,
  IFrameLoginModelPayloadModel,
  KpiDisplayOptionsModel,
  UserPayloadModel,
} from '../../components/common/toolbar/settingsPanel/settingsPanel.types';
import { PayloadModel, RequestStatusModel } from '../../models/global';
import Api from '../../services/api';
import { UserModel } from './authoriztion.types';
import { checkKpiDisplayOptionsFields } from '../../helpers';

export interface InitialState {
  token: string | null;
  userInfo: UserModel | null;
  user_id: string;
  userInfoLoading: RequestStatusModel;
  kpiDisplaySettings: KpiDisplayOptionsModel | null;
  isAppLoading: boolean;
  userIPv4: string | null;
  logoutLoading: boolean;
}

const initialState: InitialState = {
  token: localStorage.getItem('token'),
  userInfo: null,
  user_id: localStorage.getItem('user_id'),
  userInfoLoading: Api.initialStatus,
  kpiDisplaySettings: null,
  isAppLoading: false,
  userIPv4: null,
  logoutLoading: false,
};

const authorization = createSlice({
  name: 'authorization',
  initialState,
  reducers: {
    login: (state, { payload }: PayloadAction<IFrameLoginModelPayloadModel>) => {
      console.log('LOGIN', payload);
      localStorage.setItem('token', payload.token);
      payload.user_id && localStorage.setItem('user_id', `${payload.user_id}`);
      state.user_id = `${payload.user_id || ''}`;
      state.token = payload.token;
    },
    logout: state => {
      state.logoutLoading = true;
    },
    logoutSuccess: state => {
      localStorage.removeItem('token');
      localStorage.removeItem('user_id');
      state.token = null;
      state.userInfo = null;
      state.user_id = '';
      state.kpiDisplaySettings = null;
      state.logoutLoading = false;
    },
    setAppIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isAppLoading = payload;
    },
    getUserInfo: (state, payload: PayloadAction<UserPayloadModel>) => {
      state.userInfoLoading = Api.requestStatus;
      state.isAppLoading = true;
    },
    getUserInfoByToken: (state, payload: PayloadAction<UserPayloadModel>) => {
      state.userInfoLoading = Api.requestStatus;
      state.isAppLoading = true;
    },
    grantLogin: (state, payload: PayloadAction<{ grant_token: string; callback_url: string }>) => {
      state.isAppLoading = true;
    },
    grantLoginSuccess: (state, { payload }: PayloadAction<UserModel>) => {
      state.userInfoLoading = Api.successStatus;
      payload.kpi_display_options = checkKpiDisplayOptionsFields({
        display_settings: payload.kpi_display_options?.display_settings || null,
        advanced_display_settings: payload.kpi_display_options?.advanced_display_settings || null,
      });

      const newPayload = { ...payload };
      state.userInfo = newPayload;

      localStorage.setItem('token', payload['accessToken']);
      state.token = payload['accessToken'];
      state.user_id = payload.id.toString();

      let new_display_settings;
      if (newPayload.kpi_display_options) {
        new_display_settings = {
          ...newPayload.kpi_display_options.display_settings,
          legends: window.innerWidth < 1201 ? false : newPayload.kpi_display_options.display_settings.legends,
          statistics: window.innerWidth < 1201 ? false : newPayload.kpi_display_options.display_settings.statistics,
          cursorLine: window.innerWidth < 1201 ? false : newPayload.kpi_display_options.display_settings.cursorLine,
        };
        state.kpiDisplaySettings = {
          ...newPayload.kpi_display_options,
          display_settings: new_display_settings,
        };
      } else {
        state.kpiDisplaySettings = payload.kpi_display_options;
      }
    },
    getUserInfoSuccess: (state, { payload }: PayloadAction<UserModel>) => {
      state.userInfoLoading = Api.successStatus;
      payload.kpi_display_options = checkKpiDisplayOptionsFields({
        display_settings: payload.kpi_display_options?.display_settings || null,
        advanced_display_settings: payload.kpi_display_options?.advanced_display_settings || null,
      });

      const newPayload = { ...payload };
      state.userInfo = newPayload;

      let new_display_settings;
      if (newPayload.kpi_display_options) {
        new_display_settings = {
          ...newPayload.kpi_display_options.display_settings,
          legends: window.innerWidth < 1201 ? false : newPayload.kpi_display_options.display_settings.legends,
          statistics: window.innerWidth < 1201 ? false : newPayload.kpi_display_options.display_settings.statistics,
          cursorLine: window.innerWidth < 1201 ? false : newPayload.kpi_display_options.display_settings.cursorLine,
        };
        state.kpiDisplaySettings = {
          ...newPayload.kpi_display_options,
          display_settings: new_display_settings,
        };
      } else {
        state.kpiDisplaySettings = payload.kpi_display_options;
      }
    },
    setKpiDisplaySettings: (state, payload: PayloadModel<KpiDisplayOptionsModel, null>) => {
      state.kpiDisplaySettings = {
        ...state.kpiDisplaySettings,
        ...(payload.payload?.data || {}),
      };
    },
    setKpiDisplaySettingsSuccess: (state, { payload }: PayloadAction<UserModel>) => {
      state.userInfo = payload;
    },
    setDisplaySettings: (state, { payload }: PayloadAction<DisplaySettingsModel>) => {
      if (state.kpiDisplaySettings?.display_settings) {
        state.kpiDisplaySettings.display_settings = { ...payload };
      } else {
        state.kpiDisplaySettings = {
          display_settings: { ...payload },
          advanced_display_settings: state.kpiDisplaySettings?.advanced_display_settings || null,
        };
      }
    },
    setAdvancedDisplaySettings: (state, { payload }: PayloadAction<AdvancedDisplaySettingsModel>) => {
      if (state.kpiDisplaySettings?.advanced_display_settings) {
        state.kpiDisplaySettings.advanced_display_settings = { ...payload };
      } else {
        state.kpiDisplaySettings = {
          advanced_display_settings: { ...payload },
          display_settings: state.kpiDisplaySettings?.display_settings || null,
        };
      }
    },
    clearState: state => {
      // state = initialState;
    },
    getIPv4: (state, { payload }) => {
      state.userIPv4 = payload;
    },
  },
});

const {
  login,
  logout,
  logoutSuccess,
  setAppIsLoading,
  getUserInfo,
  grantLogin,
  getUserInfoByToken,
  grantLoginSuccess,
  getUserInfoSuccess,
  setKpiDisplaySettings,
  setKpiDisplaySettingsSuccess,
  setDisplaySettings,
  setAdvancedDisplaySettings,
  clearState,
  getIPv4,
} = authorization.actions;

export {
  login,
  logout,
  logoutSuccess,
  setAppIsLoading,
  getUserInfo,
  getUserInfoSuccess,
  grantLogin,
  getUserInfoByToken,
  grantLoginSuccess,
  setKpiDisplaySettings,
  setKpiDisplaySettingsSuccess,
  setDisplaySettings,
  setAdvancedDisplaySettings,
  clearState,
  getIPv4,
};

export default authorization.reducer;
