import { useMemo } from 'react';

export const useGetThemeClass = (styleClass: string, themeProp?: 'light' | 'dark') => {
  const theme = themeProp || 'light';

  return useMemo(() => {
    if (theme === 'dark') {
      return styleClass + '-dark';
    } else {
      return styleClass + '-light';
    }
  }, [theme, styleClass]);
};
