import React, { useEffect } from 'react';
import { useGetThemeClass } from '../../../hooks/useGetThemeClass/useGetThemeClass';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
import { useHistory, useLocation } from 'react-router-dom';
import CustomButton from '../../controls/CustomButton/CustomButton';
import cn from 'classnames';
import { MenuItem } from '@material-ui/core';
import { UserModel } from '../../../store/authData/authoriztion.types';
import { getProject, setClient } from '../../../store/clientProjectsData/clientProjectsSlice';
import { ROOTS } from '../../../routes/routes';
import LogoView from '../../common/Logo/LogoView';
import PlaceholderInfo from '../../controls/PlaceholderInfo/PlaceholderInfo';
import { logout } from '../../../store/authData/authoriztionSlice';

import './ChangeCompanyPage.styles.scss';

const ChangeCompanyPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const themeClass = useGetThemeClass('b-authorization-loginPage');

  const userInfo = useSelector<RootState>(state => state.authorization.userInfo) as UserModel | null;
  const selected_client_id = useSelector<RootState>(state => state.clientsProjectsReducer.currentClientId) as number;
  const logoutLoading = useSelector<RootState>(state => state.authorization.logoutLoading) as boolean;

  const clients = userInfo?.clients || [];
  const isChangeProject = location.pathname.split('/')[1] === 'change-project';
  const selectedClient = clients?.find(client => client.id === selected_client_id);
  const clientProjects = userInfo?.projects.filter(project => project.client_id === selected_client_id) || [];
  const menuItems = isChangeProject ? clientProjects : clients;

  const handleChangeCompany = () => {
    dispatch(setClient(0));
    history.push(ROOTS.CHANGE_COMPANY);
  };
  const selectProject = (projectId: number) => {
    dispatch(
      getProject({
        data: {
          id: projectId,
        },
      }),
    );

    const projectCode = clientProjects.find(project => project.id === projectId);
    history.push(`/${projectCode.code}`);
  };
  const selectClient = (clientId: number) => {
    dispatch(setClient(clientId));
  };
  const handleLogout = () => {
    dispatch(logout());
    dispatch(setClient(0));
  };

  return (
    <>
      <div className={`${themeClass}_container`}>
        <div className={`${themeClass}_topInfo`}>
          <div className={`${themeClass}_logoContainer`}>
            <LogoView isFull themeProp={'light'} />
            <p className={`${themeClass}_logoContainer__header`}>Plan & Control App</p>
          </div>
          <div className={`${themeClass}_form`}>
            <div className={`${themeClass}_signedInContainer`}>
              <div className={`${themeClass}_signedInContainer_title`}>Welcome back, {userInfo?.first_name}!</div>
              <div className={`${themeClass}_signedInContainer_subTitle`}>
                You signed in as <b>{userInfo?.email}</b>
              </div>
            </div>

            {!!clients && !!clients.length && (
              <>
                {isChangeProject && selectedClient && (
                  <div className={`${themeClass}_companyRow`}>
                    <div className={`${themeClass}_companyRow_left`}>
                      <label className={`${themeClass}_textLabel`}>Company</label>
                      <PlaceholderInfo
                        size={24}
                        type={'Company'}
                        tooltip={selectedClient.title}
                        detailInfo={selectedClient.title}
                        imagePath={selectedClient.renderings?.[0]?.file}
                      />
                    </div>
                    <CustomButton type={'text-plain'} size={'xs'} title={'Change'} clickHandler={handleChangeCompany} />
                  </div>
                )}
                <div className={`${themeClass}_headRow`}>
                  <label className={`${themeClass}_textLabel`}>{isChangeProject ? 'Select project' : 'Choose the company'}</label>
                </div>
                <ul className={cn(`${themeClass}_clientsList`, { ['-projectSelect']: isChangeProject })}>
                  {menuItems.map(({ id, title, status }: any) => {
                    return (
                      <MenuItem
                        key={`company-${id}`}
                        className={`${themeClass}_menuItemClients menuItemClients clientsList clients__popover__menuItem`}
                        value={id}
                        onClick={e => (isChangeProject ? selectProject(+e.currentTarget.value) : selectClient(+e.currentTarget.value))}
                        disableRipple
                      >
                        <div className={`${themeClass}_menuItem`}>
                          <p className={status === 'archived' ? `${themeClass}_menuItem_archivedTitle -whiteSpace` : ''}>{title}</p>
                          {status === 'archived' && (
                            <p className={status === 'archived' ? `${themeClass}_menuItem_archivedTitle` : ''}>Archived</p>
                          )}
                        </div>
                      </MenuItem>
                    );
                  })}
                </ul>
                <span className={`${themeClass}_signOut`} onClick={handleLogout}>
                  <CustomButton type={'text-plain'} size={'xs'} title={'Sign out'} clickHandler={handleLogout} loading={logoutLoading} />
                </span>
              </>
            )}
          </div>
        </div>
        <div className={`${themeClass}_bottomInfo`}>
          <span className={`${themeClass}_bottomInfo_textInfo`}>Planit Inc., {new Date().getFullYear()}</span>
        </div>
      </div>
    </>
  );
};
export default ChangeCompanyPage;
