import React, { useMemo } from 'react';
import FULL_LIGHT from '../../../assets/icons/planit_logo_light_full.svg';
import FULL_DARK from '../../../assets/icons/planit_logo_dark_full.svg';
import SMALL_LIGHT from '../../../assets/icons/planit_logo_light.svg';
import SMALL_DARK from '../../../assets/icons/planit_logo_dark.svg';
import SVG from 'react-inlinesvg';

import './LogoStyles.scss';

interface LogoProps {
  isFull: boolean;
  themeProp?: 'light' | 'dark';
}

const LogoView: React.FC<LogoProps> = ({ isFull, themeProp }) => {
  const logoSvg = useMemo(() => {
    if (isFull) {
      return themeProp === 'dark' ? FULL_DARK : FULL_LIGHT;
    } else {
      return themeProp === 'dark' ? SMALL_DARK : SMALL_LIGHT;
    }
  }, [isFull, themeProp]);

  return (
    <div className={`Logo__link`} id={'planitLogo-tourBlock'}>
      <SVG src={logoSvg} />
    </div>
  );
};

export default LogoView;
