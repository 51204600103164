import React, { FC, MouseEvent, ReactElement } from 'react';
import Placeholder from '../Placeholder/Placeholder';
import CustomTooltip from '../Tooltip/Tooltip';
import cn from 'classnames';
import { useGetThemeClass } from '../../../hooks/useGetThemeClass/useGetThemeClass';

import './PlaceholderInfoStyles.scss';

const PlaceholderInfo: FC<{
  type: 'Company' | 'Persona';
  imagePath?: string;
  firstName?: string;
  lastName?: string;
  detailInfo: string;
  subInfo?: string | ReactElement;
  size: 24 | 32 | 56;
  tooltip?: string | ReactElement | null;
  isMobile?: boolean;
  customClassName?: string;
  isTruncateHeader?: boolean;
  isHeader?: boolean;
  clickHandler?: (e: MouseEvent) => void;
  markedTitle?: boolean;
}> = ({
  type,
  imagePath,
  size,
  firstName,
  lastName,
  detailInfo,
  subInfo,
  isTruncateHeader,
  tooltip,
  isMobile,
  customClassName,
  isHeader,
  clickHandler,
  markedTitle,
}) => {
  const themeClass = useGetThemeClass('b-placeholderInfo');

  return (
    <>
      <div
        className={cn(`${themeClass}`, {
          [`${customClassName}`]: customClassName,
          ['-mobile']: isMobile,
        })}
      >
        <CustomTooltip
          customBasisClass={`${themeClass}_tooltipBasis`}
          title={tooltip === null ? '' : tooltip || detailInfo || ''}
          placement={'bottom'}
        >
          <div className={`${themeClass}_content`}>
            <Placeholder type={type} imagePath={imagePath} size={size} firstName={firstName} lastName={lastName} />

            {isMobile ? (
              <>
                {!isHeader && (
                  <div className={`${themeClass}_detailSubContainer`}>
                    <div
                      className={cn(`${themeClass}_detail ${themeClass}_detail_${size}`, {
                        ['-clickable']: clickHandler,
                        ['-marked']: markedTitle,
                      })}
                      onClick={clickHandler && clickHandler}
                    >
                      {detailInfo}
                    </div>
                    <div className={`${themeClass}_sub ${themeClass}_sub_${size}`}>{subInfo}</div>
                  </div>
                )}
              </>
            ) : (
              <div className={`${themeClass}_detailSubContainer`}>
                <div
                  className={cn(`${themeClass}_detail ${themeClass}_detail_${size}`, {
                    ['-clickable']: clickHandler,
                    ['-marked']: markedTitle,
                  })}
                  onClick={clickHandler && clickHandler}
                >
                  {detailInfo}
                </div>
                <div className={`${themeClass}_sub ${themeClass}_sub_${size} ${isTruncateHeader ? '-headerTrunk' : ''}`}>{subInfo}</div>
              </div>
            )}
          </div>
        </CustomTooltip>
      </div>
    </>
  );
};

export default React.memo(PlaceholderInfo);
