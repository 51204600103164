import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUserInfoByToken, grantLogin } from '../../../store/authData/authoriztionSlice';
import { RootState } from '../../../store/reducers';
import { ROOTS } from '../../../routes/routes';

const Auth = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const location = useLocation();
  const token = useSelector<RootState>(state => state.authorization.token);
  const client_id = useSelector<RootState>(state => state.clientsProjectsReducer.currentClientId);

  const savedBuildingId = JSON.parse(localStorage.getItem('savedBuildingId'));
  const savedProjectName = JSON.parse(localStorage.getItem('savedProjectName'));

  useEffect(() => {
    if (!token) {
      const grantToken = new URLSearchParams(location.search).get('grant_token');
      const localKpiUrl = window.location.href;

      if (grantToken) {
        dispatch(grantLogin({ grant_token: grantToken, callback_url: localKpiUrl }));
      } else {
        const localUrl = process.env.REACT_APP_AUTH_SERVICE_URI;
        const encodedKpiUrl = encodeURIComponent(localKpiUrl);
        window.location.href = `${localUrl}?grant_token&callback_url=${encodedKpiUrl}`;
      }
    } else {
      dispatch(getUserInfoByToken());
      //TODO implement another solution (update project to actual node version and use actual libraries)
      // if (!client_id) {
      //   history.push(ROOTS.CHANGE_COMPANY);
      // }
      // if (client_id && !savedProjectName) {
      //   history.push(ROOTS.CHANGE_PROJECT);
      // }
      // if (savedBuildingId && savedProjectName) {
      // history.push(`/${savedProjectName}/${savedBuildingId}/graph`);
      // }
    }
  }, [location.search]);

  return <div>Redirecting...</div>;
};

export default Auth;
